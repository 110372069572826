import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import swal from "sweetalert";

import "./styles/contact.css";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

function CursorHoverInit() {
  useEffect(() => {
    console.log("hellooooo in here cursor hover init");
    var swalButton = document.getElementsByClassName("swal-button");
    const cursor = document.getElementById("cursor");
    Array.from(swalButton).forEach(function (element) {
      element.addEventListener("mouseenter", function (event) {
        cursor.classList.add("active");
      });
      element.addEventListener("mouseleave", function (event) {
        cursor.classList.remove("active");
      });
    });
  }, []);
  return <div></div>;
}

export default function Contact(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [info, setInfo] = useState("");

  const handleSubmit = () => {
    let wrapper = document.createElement("div");
    ReactDOM.render(<CursorHoverInit />, wrapper);
    let el = wrapper.firstChild;
    if (name && email && phone) {
      axios.defaults.headers.post["Content-Type"] = "application/json";
      axios
        .post("https://formsubmit.co/ajax/a5ded51ce637d24382b56109bfa25a1b", {
          name: "FormSubmit -- Undefined Site",
          message: `
          name: ${name},
          email: ${email},
          phone: ${phone},
          info: ${info}
        `,
        })
        .then(function (response) {
          var swalButton = document.getElementsByClassName("swal-button");
          const cursor = document.getElementById("cursor");
          Array.from(swalButton).forEach(function (element) {
            element.addEventListener("mouseenter", function (event) {
              cursor.classList.add("active");
            });
            element.addEventListener("mouseleave", function (event) {
              cursor.classList.remove("active");
            });
          });
          swal("Thanks for contacting us! We'll be in touch shortly.", {
            button: {
              className: "hoverable",
            },
            content: el,
          });
        })
        .catch(function (error) {
          console.log("Form submit error: ", error);
        });
    } else {
      var swalButton = document.getElementsByClassName("swal-button");
      const cursor = document.getElementById("cursor");
      Array.from(swalButton).forEach(function (element) {
        element.addEventListener("mouseenter", function (event) {
          cursor.classList.add("active");
        });
        element.addEventListener("mouseleave", function (event) {
          cursor.classList.remove("active");
        });
      });
      swal(
        "Please fill out your name, email and phone number so we can get in touch, thanks!",
        {
          button: {
            className: "hoverable",
          },
          content: el,
        }
      );
    }
  };

  const handleChange = (event, type) => {
    if (type === "name") {
      setName(event.target.value);
    } else if (type === "email") {
      setEmail(event.target.value);
    } else if (type === "phone") {
      setPhone(event.target.value);
    } else if (type === "info") {
      setInfo(event.target.value);
    }
  };

  return (
    <div className="contact-container">
      <form>
        <div className="contact-input-container">
          <div style={{ position: "relative", width: 300 }}>
            <label id="name-label" for="name" className="contact-label">
              Name
            </label>
            <input
              className="contact-input"
              type="text"
              name="name"
              // placeholder="Name"
              value={name}
              onFocus={() => {
                console.log("FOcused");
                var focusedItem = document.getElementById("name-label");
                focusedItem.classList.add("active");
              }}
              onBlur={() => {
                console.log("Not focused");
                // * If name is empty move label back down
                var focusedItem = document.getElementById("name-label");
                if (!name) {
                  focusedItem.classList.remove("active");
                }
              }}
              onChange={(event) => handleChange(event, "name")}
            />
          </div>
          <div style={{ position: "relative", width: 300 }}>
            <label id="email-label" for="email" className="contact-label">
              Email
            </label>
            <input
              className="contact-input"
              type="text"
              name="email"
              // placeholder="Name"
              value={email}
              onFocus={() => {
                console.log("FOcused");
                var focusedItem = document.getElementById("email-label");
                focusedItem.classList.add("active");
              }}
              onBlur={() => {
                console.log("Not focused");
                // * If email is empty move label back down
                var focusedItem = document.getElementById("email-label");
                if (!email) {
                  focusedItem.classList.remove("active");
                }
              }}
              onChange={(event) => handleChange(event, "email")}
            />
          </div>
        </div>
        <div className="contact-input-container contact-input-container-lower">
          <div style={{ position: "relative", width: 300 }}>
            <label id="phone-label" for="phone" className="contact-label">
              Phone Number
            </label>
            <input
              className="contact-input"
              type="text"
              name="phone"
              // placeholder="Name"
              value={phone}
              onFocus={() => {
                console.log("FOcused");
                var focusedItem = document.getElementById("phone-label");
                focusedItem.classList.add("active");
              }}
              onBlur={() => {
                console.log("Not focused");
                // * If name is empty move label back down
                var focusedItem = document.getElementById("phone-label");
                if (!phone) {
                  focusedItem.classList.remove("active");
                }
              }}
              onChange={(event) => handleChange(event, "phone")}
            />
          </div>
          <div style={{ position: "relative", width: 300 }}>
            <label id="info-label" for="info" className="contact-label">
              More Information
            </label>
            <input
              className="contact-input"
              type="text"
              name="info"
              // placeholder="Name"
              value={info}
              onFocus={() => {
                console.log("FOcused");
                var focusedItem = document.getElementById("info-label");
                focusedItem.classList.add("active");
              }}
              onBlur={() => {
                console.log("Not focused");
                // * If info is empty move label back down
                var focusedItem = document.getElementById("info-label");
                if (!info) {
                  focusedItem.classList.remove("active");
                }
              }}
              onChange={(event) => handleChange(event, "info")}
            />
          </div>
        </div>
        <div className="submit-container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <div className="contact-button hoverable">
              <PhoneIcon
                className="contact-button-icon"
                style={{ fontSize: 25, marginRight: 10 }}
              />
              <p>(203)258-9303</p>
            </div>
            <div className="contact-button hoverable">
              <EmailIcon
                className="contact-button-icon"
                style={{ fontSize: 25, marginRight: 10 }}
              />
              <p>contact@undefinedllc.com</p>
            </div>
          </div>
          <div
            className="submit-button hoverable"
            onClick={() => handleSubmit()}
          >
            <ArrowForwardIcon
              className="submit-button-icon"
              style={{ fontSize: 35 }}
            />
          </div>
        </div>
      </form>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 50,
        }}
      ></div>
    </div>
  );
}
